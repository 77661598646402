import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../Animation - 1721084896619.json';
import classes from './Loading.module.css';

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={classes.loaderWrapper}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default Loading;
