import React, { useEffect, useState, useRef, useCallback } from 'react';
import { collection, deleteDoc, doc, getDoc, query, orderBy, startAfter, limit, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase-config';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import classes from './Home.module.css';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Home = () => {
    const [postList, setPostList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastDoc, setLastDoc] = useState(null);
    let navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth") === 'true');
    const [currentUser, setCurrentUser] = useState(auth.currentUser);
    const observer = useRef();
    const postCollectionRef = collection(db, "post");

    const preloadImages = async (imageUrls) => {
        await Promise.all(imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = resolve;
                img.onerror = reject;
            });
        }));
    };

    const loadMorePosts = useCallback((node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && lastDoc) {
                setLoading(true);
                const postQuery = query(postCollectionRef, orderBy('date', 'desc'), startAfter(lastDoc), limit(10));
                getDocs(postQuery).then(async (snapshot) => {
                    const newPosts = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    const imageUrls = newPosts.flatMap(post => post.imageUrls || []);
                    await preloadImages(imageUrls);
                    setPostList(prevPosts => [...prevPosts, ...newPosts]);
                    setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
                    setLoading(false);
                }).catch((error) => {
                    console.error("Error loading more posts:", error);
                    setLoading(false);
                });
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, lastDoc]);

    useEffect(() => {
        const initialLoad = async () => {
            setLoading(true);
            const postQuery = query(postCollectionRef, orderBy('date', 'desc'), limit(10));
            const snapshot = await getDocs(postQuery);
            const initialPosts = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const imageUrls = initialPosts.flatMap(post => post.imageUrls || []);
            await preloadImages(imageUrls);
            setPostList(initialPosts);
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            setLoading(false);
        };

        initialLoad().catch((error) => {
            console.error("Error loading initial posts:", error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setIsAuth(!!user);
        });

        return () => unsubscribe();
    }, []);

    const deletePost = async (id, imageUrls) => {
        if (!isAuth || !currentUser) {
            alert("You are not authorized to delete posts.");
            return;
        }

        const postDoc = doc(db, "post", id);
        const postSnap = await getDoc(postDoc);

        if (postSnap.exists()) {
            const postData = postSnap.data();
            if (postData.createdBy !== currentUser.uid) {
                console.error("You are not authorized to delete this post.");
                return;
            }

            try {
                await deleteDoc(postDoc);

                if (imageUrls && imageUrls.length > 0) {
                    const storage = getStorage();
                    for (const imageUrl of imageUrls) {
                        const imageRef = ref(storage, imageUrl);
                        await deleteObject(imageRef);
                        console.log(`Deleted image ${imageUrl} successfully.`);
                    }
                }

                // Remove the deleted post from the state
                setPostList(prevPosts => prevPosts.filter(post => post.id !== id));
                closeDeletePopup();
            } catch (error) {
                console.error('Error deleting post or images:', error);
            }
        }
    };

    const editPost = (id) => {
        navigate(`/edit/${id}`);
    };

    const [showGallery, setShowGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const openGallery = (images, startIndex) => {
        const galleryImages = images.map(url => ({
            original: url,
            thumbnail: false,
            description: '',
        }));
        setGalleryImages(galleryImages);
        setGalleryIndex(startIndex);
        setShowGallery(true);
    };

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);

    const openDeletePopup = (post) => {
        setPostToDelete(post);
        setShowDeletePopup(true);
    };

    const closeDeletePopup = () => {
        setPostToDelete(null);
        setShowDeletePopup(false);
    };

    const confirmDeletePost = () => {
        if (postToDelete) {
            deletePost(postToDelete.id, postToDelete.imageUrls);
        }
    };

    return (
        <div className={classes.homePage}>
            {loading && postList.length === 0 ? (
                <div><Loading/></div>
                  
            ) : (
                <>
                    {postList.map((post, index) => (
                        <div className={classes.post} ref={index === postList.length - 1 ? loadMorePosts : null} key={post.id}>
                            <div className={classes.postContent}>
                                <span className={classes.date}>{post.date}</span>
                                <span className={classes.title}>{post.title}</span>
                                <span className={classes.content} dangerouslySetInnerHTML={{ __html: post.content }} />
                            </div>
                            {post.imageUrls && (
                                <div className={classes.imageScrollContainer}>
                                    {post.imageUrls.map((imageUrl, index) => (
                                        <LazyLoad key={index} height={200} offset={100}>
                                            <img
                                                src={imageUrl}
                                                alt={`Image ${index}`}
                                                onClick={() => openGallery(post.imageUrls, index)}
                                            />
                                        </LazyLoad>
                                    ))}
                                </div>
                            )}
                            {isAuth && currentUser && currentUser.uid === post.createdBy && (
                                <div className={classes.actionButtons}>
                                    <button className={`${classes.editPost} ${classes.actionButton}`} onClick={() => editPost(post.id)}>Edit</button>
                                    <button className={`${classes.deletePost} ${classes.actionButton}`} onClick={() => openDeletePopup(post)}>Delete</button>
                                </div>
                            )}
                        </div>
                    ))}

                    {showGallery && (
                        <div className={classes.galleryModal} onClick={() => setShowGallery(false)}>
                            <div className={classes.galleryContent} onClick={(e) => e.stopPropagation()}>
                                <button className={classes.closeGallery} onClick={() => setShowGallery(false)}>X</button>
                                <ImageGallery
                                    items={galleryImages}
                                    showIndex={true}
                                    startIndex={galleryIndex}
                                    showPlayButton={false}
                                    onClose={() => setShowGallery(false)}
                                    infinite={true}
                                    useBrowserFullscreen={true}
                                    renderLeftNav={(onClick, disabled) => (
                                        <button
                                            className={classes.imageGalleryLeftNav}
                                            disabled={disabled}
                                            onClick={onClick}
                                            aria-label="Previous Slide"
                                        >
                                            &lt;
                                        </button>
                                    )}
                                    renderRightNav={(onClick, disabled) => (
                                        <button
                                            className={classes.imageGalleryRightNav}
                                            disabled={disabled}
                                            onClick={onClick}
                                            aria-label="Next Slide"
                                        >
                                            &gt;
                                        </button>
                                    )}
                                />
                            </div>
                        </div>
                    )}

                    {isAuth && showDeletePopup && (
                        <div className={classes.deletePopup}>
                            <div className={classes.deletePopupContent}>
                                <button className={classes.closePopup} onClick={closeDeletePopup}>X</button>
                                <span className={classes.deletePopupText} >Are you sure you want to delete this post?</span>
                                <div className={classes.popupButtons}>
                                    <button className={classes.cancelButton} onClick={closeDeletePopup}>Cancel</button>
                                    <button className={classes.confirmButton} onClick={confirmDeletePost}>Delete</button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
