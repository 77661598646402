import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage, auth } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classes from './CreatePost.module.css';

const CreatePost = ({ isAuth, logout }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state

  const postCollectionRef = collection(db, 'post');
  let navigate = useNavigate();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setImages(filesArray);
    }
  };

  const uploadImages = async (uid) => {
    const uploadPromises = images.map(async (image) => {
      const uniqueName = `${Date.now()}_${image.name}`;
      const imageRef = ref(storage, `images/${uniqueName}`);
      const metadata = {
        customMetadata: {
          userId: uid
        }
      };
      await uploadBytes(imageRef, image, metadata);
      return getDownloadURL(imageRef);
    });
    return Promise.all(uploadPromises);
  };

  const createPost = async () => {
    if (!content) {
      setErrorMessage("No content!");
      return;
    }
    
    if (isSubmitting) {
      return; // Prevent submission if already in progress
    }

    setIsSubmitting(true); // Set loading state

    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("User not authenticated");
      setIsSubmitting(false); // Reset loading state
      return;
    }

    const currentDate = new Date();
    const date = formatDate(currentDate);

    try {
      const uploadedUrls = await uploadImages(currentUser.uid);

      const postData = {
        title,
        content,
        date,
        createdBy: currentUser.uid,
        imageUrls: uploadedUrls
      };

      await addDoc(postCollectionRef, postData);

      navigate('/');
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  const cancelPost = () => {
    navigate('/');
  };

  return (
    <div className={classes.createPostPage}>
      <div className={classes.createPostContainer}>
        <div className={classes.inputGp}>
          <input className={classes.createPostTitle} required placeholder='Title' onChange={(event) => setTitle(event.target.value)} />
        </div>
        <div className={classes.inputGp}>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'size': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image', 'video'
            ]}
            placeholder='Sta ima, Jurich?'
            required
          />
        </div>
        <div className={classes.inputGp}>
          <input type='file' multiple onChange={handleImageChange} />
        </div>
        <p>{errorMessage}</p>
        <div className={classes.buttonContainer}>
          <button onClick={cancelPost} className={classes.cancelButton}>Cancel</button>
          {isAuth && (
            <button 
              className={classes.submitButton} 
              onClick={createPost} 
              disabled={isSubmitting} // Disable button if submitting
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
