import React, { useEffect, useState, Fragment } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, storage, auth } from '../../firebase-config';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classes from './EditPost.module.css';
import Loading from '../../components/Loading/Loading';

const EditPost = ({ isAuth, logout }) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [originalDate, setOriginalDate] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newImages, setNewImages] = useState([]);
    let navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchPost = async () => {
            if (!id) {
                navigate("/");
                return;
            }

            const postDoc = doc(db, "post", id);
            const postSnap = await getDoc(postDoc);

            if (postSnap.exists()) {
                const postData = postSnap.data();
                if (postData.createdBy !== auth.currentUser.uid) {
                    console.error("You are not authorized to edit this post.");
                    navigate("/");
                    return;
                }
                setTitle(postData.title);
                setContent(postData.content);
                setOriginalDate(postData.date);
                setImageUrls(postData.imageUrls || []);
            } else {
                console.error("No such document!");
                navigate("/");
            }
            setLoading(false);
        };

        fetchPost();
    }, [id, navigate]);

    const editPost = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }

        const postDoc = doc(db, "post", id);
        const updatedImageUrls = [...imageUrls];

        if (newImages.length > 0) {
            for (const image of newImages) {
                const uniqueName = `${Date.now()}_${image.name}`;
                const imageRef = ref(storage, `images/${uniqueName}`);
                const metadata = {
                    customMetadata: {
                        userId: currentUser.uid
                    }
                };
                try {
                    await uploadBytes(imageRef, image, metadata);
                    const downloadUrl = await getDownloadURL(imageRef);
                    updatedImageUrls.push(downloadUrl);
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }
        }

        await updateDoc(postDoc, {
            title,
            content,
            date: originalDate,
            imageUrls: updatedImageUrls
        });
        navigate("/");
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        setNewImages([...files]);
    };

    const removeImage = async (imageUrl) => {
        const imageRef = ref(storage, imageUrl);
        try {
            await deleteObject(imageRef);
            console.log(`Deleted image ${imageUrl} successfully.`);
        } catch (error) {
            console.error('Error deleting image:', error);
        }

        const filteredImageUrls = imageUrls.filter(url => url !== imageUrl);
        setImageUrls(filteredImageUrls);
    };

    const cancelEdit = () => {
        navigate("/");
    };

    if (loading) {
        return <div><Loading/></div>;
    }

    return (
        <Fragment>
            <div className={classes.editPostContainer}>
                <div className={classes.inputGp}>
                    <input className={classes.editPostTitle}
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </div>
                <div className={classes.inputGp}>
                    <ReactQuill
                        value={content}
                        onChange={setContent}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                [{ 'size': [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                ['link', 'image', 'video'],
                                ['clean']
                            ],
                        }}
                        formats={[
                            'header', 'font', 'size',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image', 'video'
                        ]}
                        placeholder='Sta ima, Jurich?'
                    />
                </div>
                <div className={classes.inputGp}>
                    <input type="file" multiple onChange={handleImageChange} />
                </div>
                <div className={classes.imageContainer}>
                    {imageUrls.map((imageUrl, index) => (
                        <div key={index} className={classes.imagePreview}>
                            <img src={imageUrl} alt={`preview-${index}`} />
                            <button onClick={() => removeImage(imageUrl)} className={classes.deleteButton}>X</button>
                        </div>
                    ))}
                </div>
                <div className={classes.buttonContainer}>
                    <button onClick={cancelEdit} className={classes.cancelButton}>Cancel</button>
                    <button onClick={editPost} className={classes.saveButton}>Save</button>
                </div>
            </div>
        </Fragment>
    );
};

export default EditPost;
