import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import CreatePost from "./pages/createPost/CreatePost";
import EditPost from "./pages/editPost/EditPost";
import Login from "./pages/login/Login";
import NotFound from './components/NotFound'; // Import the NotFound component
import { useState } from 'react';
import { signOut } from "firebase/auth";
import { auth } from './firebase-config';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar/Navbar';

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth") === 'true');

  const logout = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/login";
    });
  };

  return (
    <BrowserRouter>
    <Navbar isAuth={isAuth} logout={logout} />
      <Routes>
        <Route path='/' element={<Home logout={logout} />} />
        <Route path='/create' element={
          <ProtectedRoute isAuth={isAuth}>
            <CreatePost isAuth={isAuth} logout={logout} />
          </ProtectedRoute>
        } />
        <Route path='/edit/:id' element={
          <ProtectedRoute isAuth={isAuth}>
            <EditPost isAuth={isAuth} logout={logout} />
          </ProtectedRoute>
        } />
        <Route path='/login' element={<Login setIsAuth={setIsAuth} />} />
        <Route path='/*' element={<NotFound />} /> {/* Use NotFound for catch-all route */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
