import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';
import classes from './Login.module.css';

const Login = ({ setIsAuth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setError(null); // Reset error state

    if (!email || !password) {
      setError('Email and password are required.');
      setEmail('');
      setPassword('');
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('isAuth', 'true'); // Storing as string
      setIsAuth(true);
      navigate('/');
    } catch (error) {
      console.error('Error logging in:', error.message);
      setError('Failed to log in. Please check your email and password.');
      setEmail('');
      setPassword('');
    }
  };

  return (
    <div className={classes.loginPage}>
      <div className={classes.loginContainer}>
        <h1>Log in</h1>
        <form onSubmit={login}>
          <div className={classes.inputGp}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className={classes.inputGp}>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button className={classes.loginButton} type="submit">Log in</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
